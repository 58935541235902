.App-header {
  background-image: url('https://nearprotocol.com/wp-content/uploads/2019/03/illo-developers.svg');
  min-height: 100vh;
  font-size: 1rem;
  text-align: center;
  padding:2%; 
}

.App-link {
  color: #61dafb;
}


.apps {
  background: #fff;
  text-align: left;
  margin: 3em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  pointer-events: none;
}

.margin-logo {
  margin: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.profile-image {
  height: 3em;
  width: 3em;
  border-radius: 50%;
}

.profile-name {
  overflow: hidden;
  margin-left: 0.5em;
  vertical-align: middle;
}

.letter-small {
  overflow: hidden;
  height: 3em;
  line-height: 3em;
  cursor: pointer;
}

.letter-expanded {
}

.letter-expanded-header {
  background: #eee;
  overflow: hidden;
  height: 3em;
  line-height: 3em;
  cursor: pointer;
  margin: 0;
}

.letter {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  margin: 0;
}

.letter:hover {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  box-shadow: 0px 2px 5px rgba(64, 64, 64, .5);
}

.letter-read {
  background: #eee;
}

.letter-profile {
  white-space: nowrap;
  overflow: hidden;
}

.letter-profile-image {
  height: 2.7em;
  width: 2.7em;
  border-radius: 50%;
}

.letter-profile-name {
  overflow: hidden;
  margin-left: 0.5em;
  vertical-align: middle;
}

.letter-unread {
  font-weight: bold;
}

.letter-subject {
  overflow: hidden;
}

.letter-content {
  color: #999;
  overflow: hidden;
  font-weight: normal;
}

.letter-content-expanded {
  color: #333;
  padding: 0.5em;
}

.letter-time {
  font-size: 12px;
  white-space: nowrap;
}

.image-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 33%;
}

p {
  color: #25282A;
  font-family: sans-serif;
}

button {
  padding: 5px;
  color: #0072CE;
  border-radius: 10px;
  border: 3px solid #0072CE;
}

button:hover {
  cursor: pointer;
  color: white;
  background: #0072CE;
} 